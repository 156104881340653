import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Badge,
  BlockStack,
  Button,
  Icon,
  IndexTable,
  InlineStack,
  Link,
  List,
  Page,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { ChatMajor, CircleInformationMajor, ClipboardMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { getCountryCallingCode } from "libphonenumber-js";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import { getFilterField } from "../../Assets/Mocks/User.mock";

function DeletedUser({ apiRoute = `"admin/deleteduser"`, showCustomDays = false }) {
  const childRef = useRef();
  const trialRef = useRef();
  const [copied, setCopied] = useState(false);
  const [premiumTrialDays, setPremiumTrialDays] = useState({ days: 7 });

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopied(true);
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const handleIconClick = (phoneNumber, countryCode) => {
    const callingCode = getCountryCallingCode(countryCode);
    const whatsappLink = `https://wa.me/${callingCode}${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const filterFormFields = useMemo(() => getFilterField(), []);

  const ArchivedData = useCallback((rows) => {
    if (rows?.length <= 0) return [];
    return rows?.map((row, index) => {
      const featureStatus = row?.featureStatus;
      const feedbackArray = featureStatus ? Object.keys(featureStatus) : [];
      const filteredItems = feedbackArray.filter((item) => item !== "brokenLinksEnableDate");
      return (
        <IndexTable.Row id={row._id} key={row._id} position={index} onClick={() => {}}>
          <IndexTable.Cell>{index + 1}</IndexTable.Cell>
          <IndexTable.Cell key={row._id + 1}>
            <BlockStack gap="100">
              <Link url={`https://${row.shopUrl}`} target="_blank">
                {row.storeName}
              </Link>
              <InlineStack gap="200">
                <Text>{row?.shopUrl}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.shopUrl)}>
                  <Icon source={ClipboardMinor} />
                </a>
              </InlineStack>
              <Text>{row?.shop_owner}</Text>
              <BlockStack gap="100">
                {Array.isArray(row?.feedback) &&
                  row?.feedback?.map((item, index) => <b key={index}> {item.id}</b>)}
              </BlockStack>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>{row?.country_name}</Text>
              <Text>Currency:{row.currency}</Text>
              <InlineStack gap="200">
                <Text>{row?.email}</Text>
                <a style={{ cursor: "pointer" }} onClick={() => copyToClipboard(row.email)}>
                  <Icon source={ClipboardMinor} />
                </a>
              </InlineStack>
              {row.phone && (
                <InlineStack gap="200">
                  <Text>{row?.phone}</Text>
                  <a style={{ cursor: "pointer" }} onClick={() => handleIconClick(row.phone, row.country_code)}>
                    <Icon source={ChatMajor} />
                  </a>
                </InlineStack>
              )}
              <Text>Product Count : {row?.productCount}</Text>
              <Text>Installed: {formatTimeAgo(row?.created)}</Text>
              {row.created_at ? <Text>Created : {formatTimeAgo(row?.created_at)}</Text> : null}
              <Text>lastLogin:{formatTimeAgo(row?.lastLogin)}</Text>
              {Array.isArray(row.lastLoginArray) && (
                <BlockStack>
                  <InlineStack gap={100}>
                    <Text fontWeight="bold">Last Login</Text>
                    <Tooltip
                      content={
                        <List type="number">
                          {row?.lastLoginArray?.reverse()?.map((item, index) => (
                            <List.Item key={index}>
                              <Text> {formatTimeAgo(item?.date)}</Text>
                            </List.Item>
                          ))}
                        </List>
                      }
                    >
                      <Icon source={CircleInformationMajor} tone="info"></Icon>
                    </Tooltip>
                  </InlineStack>
                  <InlineStack gap={200}>
                    {row?.lastLoginArray?.slice(0, 3)?.map((item, index) => (
                      <Text key={index}>{formatTimeAgo(item?.date)},</Text>
                    ))}
                  </InlineStack>
                </BlockStack>
              )}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100">
              <Text>App Plan : {row?.recurringPlanName}</Text>
              <Text>Free Trial: {row?.trial_days || 0} days</Text>
              <Text>Shopify Plan : {row?.plan_display_name}</Text>
              <Badge tone="warning" size="small">
                Uninstallation Time: {formatTimeAgo(row.deleted)}
              </Badge>
              <Text>
                Onboard:
                {row.isOnBoardingDone ? <Badge tone="success">Y</Badge> : <Badge tone="warning">N</Badge>}
              </Text>
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <BlockStack gap="100" key="statusKey">
              {filteredItems.map((item, index) => (
                <Badge key={index} tone={featureStatus[item] === false ? "critical" : "success"}>
                  {item}
                </Badge>
              ))}
            </BlockStack>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text> {row?.deleted ? formatTimeAgo(row.deleted) : ""}</Text>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="500">
        {showCustomDays && (
          <InlineStack blockAlign="center" gap={200}>
            <CommonForm
              onSubmit={() => childRef?.current?.fetchData()}
              formRef={trialRef}
              initialValues={premiumTrialDays}
              onFormChange={(e) => setPremiumTrialDays(e)}
              formFields={[
                {
                  id: "days",
                  name: "days",
                  type: "number",
                  min: 1,
                },
              ]}
              isSave={false}
            />
            <Button onClick={() => childRef?.current?.fetchData()} variant="primary">
              Add Custom Day
            </Button>
          </InlineStack>
        )}
        <CommonTable
          resourceName={{
            singular: "User",
            plural: "Users",
          }}
          title="Deleted User"
          queryPlaceholder="Search User by (shopUrl, email, storeName, recurringPlanId, recurringPlanName)"
          url={eval(apiRoute)}
          selectable={false}
          rowsData={ArchivedData}
          isFilterVisible
          headings={[
            { title: "No" },
            { title: "Name" },
            { title: "Email" },
            { title: "Plan" },
            { title: "status" },
            { title: "Access" },
            { title: "Actions" },
          ]}
          searchKey={["storeName", "shopUrl", "email", "recurringPlanId", "recurringPlanName"]}
          ref={childRef}
          isAdd={false}
          verticalAlign="center"
          columnContentTypes={["text", "text", "numeric", "numeric", "numeric", "text", "text"]}
          filterFormFields={filterFormFields}
          pinnedFilter={["recurringPlanType", "isOnBoardingDone", "reviewRequest", "productCount"]}
        />
      </BlockStack>
    </Page>
  );
}

export default DeletedUser;
